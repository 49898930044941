import React from 'react'
import styled from 'styled-components'
import * as dayjs from 'dayjs'

import { font, mapMetaTags, getVideoIdFromUrl, breakpoint, getBrowserTitle, getCurrentUrl } from '../util'
import { Podcast } from '../types'
import { DynamicSection } from './dynamicSection'
import SEO from './seo';
import { useAttributes } from '../hooks';
import { RecentPodcasts } from './recentPodcasts'
import { NavigationBreadcrumbs, VideoPlayer, ShareLinks, Container, HeroImage } from './ui'
import { PodcastUrls } from './podcastUrls'
import { TemplateWrapper } from './templateWrapper';

type FullPodcastProps = {
  podcast: Podcast
  pendingMessage?: string | JSX.Element
}

const FullPodcast: React.FC<FullPodcastProps> = props => {
  const { podcast, pendingMessage = 'Loading...' } = props
  const { hero_image, hero_image_description, hero_image_caption, video_link, ...attributes } = useAttributes(podcast?.attributes) as any;

  const shareLinksProps = {
    content: podcast,
    template: "Listen to this great episode I heard on Firstly",
    emailTemplate: "Check out this great episode I heard on Firstly.com!",
    emailSubject: "Listen to this great episode I heard on Firstly.com",
    children: "Share this episode",
  }

  return podcast ? (
    <>
      <SEO
        title={getBrowserTitle(podcast)}
        meta={mapMetaTags(podcast)}
        canonical={getCurrentUrl(podcast)}
      />
      <HeroImage src={hero_image} alt={hero_image_description} caption={hero_image_caption} />
      <TemplateWrapper>
        <TitleContainer>
          <NavigationBreadcrumbs links={['home', 'podcasts']} />
          <h1 className="title">{podcast.title}</h1>
          <h2 className="subtitle" dangerouslySetInnerHTML={{ __html: podcast.subtitle }} />
          <div className="publish-date">
            <span>
              {dayjs(new Date(podcast.publishDate)).format('MMMM D, YYYY')}
            </span>
            <ShareLinks {...shareLinksProps} />
          </div>
          <VideoPlayer videoId={getVideoIdFromUrl(video_link)} height="218px" />
        </TitleContainer>
        <Container>
          <PodcastUrls attributes={attributes} />
        </Container>
        <ContentWrapper>
          {podcast.sections.map((section, index) => <DynamicSection section={section} key={index} />)}
        </ContentWrapper>
        <Container>
          <ShareLinks {...shareLinksProps} />
        </Container>
        <RecentPodcasts limit={3} showSearchBar={false} />
      </TemplateWrapper>
    </>
  ) : (
    <TemplateWrapper>
      <Container>
        {pendingMessage}
      </Container>
    </TemplateWrapper>
  )
}

const ContentWrapper = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: left;
  .dynamic-section {
    padding: 0 !important;
    &:last-child {
      .body-group:last-child {
        padding-bottom: 6px;
      }
    }
  }
`

const TitleContainer = styled(Container)`
  margin-bottom: 34px;
  .publish-date {
    display: flex;
    justify-content: space-between;
    color: #666666;
    font-size: 14px;
    line-height: 3;
  }
`

export default FullPodcast
