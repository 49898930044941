import React from 'react'

import { Layout } from './layout'
import NotFoundPage from '../pages/404'
import { usePreview } from '../hooks'
import FullPodcast from './fullPodcast'
import { Podcast } from '../types'

type PodcastPreviewPageProps = {
  params: {
    "*": string;
  }
}

const PodcastPreviewPage: React.FC<PodcastPreviewPageProps> = (props) => {
  const { params } = props
  const { loading, error, data } = usePreview<Podcast>(params['*'])


  if (!data && !loading) return <NotFoundPage />
  return (<Layout>
    <FullPodcast podcast={data && data.getContentPreview} pendingMessage={error && error.message} />
  </Layout>
  )
}

export default PodcastPreviewPage
